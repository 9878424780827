import React, { useState } from "react"

import { useApp } from "../../../hooks/useApp"
import { useLanguage } from "../../../hooks/useLanguage"

export const withStoreDetails = Component => ({ name = `StoreDetails`, content }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const locales = useLanguage(`stores`)
  const types = [`range`, `details`]

  const [active, setActive] = useState(types[0])

  const paths = [
    {
      title: locales.breadcrumb,
      url: routes.STORE,
    },
  ]

  Component.displayName = name
  return <Component active={active} content={content} locales={locales} paths={paths} setActive={setActive} types={types} />
}
